<template>
  <div class="zhuaninfo">
    <van-nav-bar
      title="发布转让"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-notice-bar style="text-align: left;" wrapable :scrollable="false" text="确认发布后将扣除相应的活跃分，取消出售后将返回剩余的活跃分。"/>
      <van-form @submit="onSubmit" ref="zhuaninfo">
        <van-field
          v-model="zhuaninfo.title"
          name="title"
          label="转让标题"
          placeholder="请填写转让标题"
          :rules="[{ required: true, message: '请填写转让标题' }]"
        />
        <van-field
          v-model="zhuaninfo.price"
          name="price"
          label="转让价格"
          placeholder="请填写转让价格"
          :rules="[{ required: true, message: '请填写转让价格' }]"
        />
        <van-field
          v-model="zhuaninfo.total"
          name="total"
          label="转让数量"
          placeholder="请填写转让数量"
          :rules="[{ required: true, message: '请填写转让数量' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="danger" :loading="btnload" :native-type="btnload?'button':'submit'">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Shopinfo",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      info: {}, // 用户信息
      uploader: [], // 上传文件
      zhuaninfo: {
        title: "",
        price: "",
        total: "",
      },
      btnload: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log('1');
    },
    onClickLeft() {
      this.$router.back();
    },
    // 保存按钮
    onSubmit() {
      let that = null;
      that = this;
      that.btnload = true;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "team/zhuan/save_info",
          that.$qs.stringify({
            title: that.zhuaninfo.title,
            price: that.zhuaninfo.price,
            total: that.zhuaninfo.total
          })
        )
        .then(res => {
          console.log(res);
          that.btnload = false;
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "保存成功");
            setTimeout(() => {
              that.onClickLeft();
            }, 1500);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "保存失败");
          }
        })
        .catch(err => {
          console.log(err);
          that.btnload = false;
        })
    }
  },
}
</script>
<style lang="less">
.van-uploader__preview-delete{
  width: 20px;
  height: 20px;
  border-radius: 0 0 0 16px;
}
.van-uploader__preview-delete-icon{
  font-size: 20px;
}
.zhuaninfo {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  .main {
    overflow: hidden;
    width: 100%;
    .van-cell {
      line-height: 30px;
      .van-cell__title {
        width: 75px;
      }
      .van-cell__value {
        flex: 1;
        .van-field__control {
          text-align: right;
          justify-content: flex-end;
          .van-uploader__upload {
            margin: 0;
            border-radius: 50%;
            overflow: hidden;
          }
          .van-uploader__preview {
            margin: 0;
            .van-uploader__preview-image {
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      }
      .van-cell__right-icon {
        line-height: 30px;
      }
      .van-field__error-message {
        text-align: right;
      }
    }
  }
}
</style>